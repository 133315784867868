import {generateKey} from './key';
import {Action} from './action';

export type MenuItemTypes = 'text' | 'image';

export interface MenuItemConfig {
  type: MenuItemTypes;
  imageUrl?: string;
  extraValue?: string;
  action?: Action;
}

export class FloorMenuItem {
  key = generateKey();
  imageUrl = '';
  extraTitle = '';
  extraValue = '';
  extraRequired = false;
  extraError = '';
  action: Action;
  type!: MenuItemTypes;

  static createImage(data: any) {
    return new FloorMenuItem({
      type: 'image',
      imageUrl: data?.block_value,
      extraValue: data?.block_opt?.opt_title,
      action: Action.create(data?.block_opt),
    });
  }

  static createText(data: any) {
    return new FloorMenuItem({
      type: 'text',
      extraValue: data?.block_value,
      action: Action.create(data?.block_opt),
    });
  }

  constructor(options: MenuItemConfig) {
    const {
      type,
      imageUrl,
      extraValue,
      action,
    } = options;
    this.type = type;
    this.imageUrl = imageUrl!;
    this.extraValue = extraValue!;
    this.action = action!;
  }

  reset() {
    this.action.reset();
    this.imageUrl = '';
    this.extraValue = '';
  }

  toJson() {
    switch (this.type) {
      case 'image':
        return this.toImageJson();
      case 'text':
        return this.toTextJson();
    }
  }

  toImageJson() {
    return {
      block_type: 'IMAGE',
      block_value: this.imageUrl,
      block_opt: {
        ...this.action.toJson(),
        opt_title: this.extraValue || undefined,
      },
    }
  }

  toTextJson() {
    return {
      block_type: 'TEXT',
      block_value: this.extraValue,
      block_opt: this.action.toJson(),
    };
  }

  hasError() {
    const isActionError = this.action.hasError();
    let isExtraError = false;

    if (this.extraRequired && !this.extraValue) {
      this.extraError = `${this.extraTitle}不可为空！`;
      isExtraError = true;
    } else {
      this.extraError = '';
    }

    if (this.type === 'image' && !this.imageUrl) {
      this.extraError = '图片不可为空！';
      isExtraError = true;
    }

    return isExtraError || isActionError;
  }
}
