
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator'
import draggable from 'vuedraggable'
import GoodsCard from '../goods-card/goods-card.vue'
import { $goods, GoodsProxy } from '@/pages/floor/renovation/services/goods'
import FloorEditorFrame from '../floor-editor-frame/floor-editor-frame.vue'

@Component({
  name: 'goods-editor',
  components: {
    FloorEditorFrame,
    GoodsCard,
    draggable
  }
})
export default class GoodsEditor extends Vue {
  @PropSync('goodsList', {
    required: true,
    default: () => []
  })
  realGoodsList!: GoodsProxy[]

  @PropSync('tempGoodsType') defaultGoodsType!: string
  @PropSync('sbGoods', { default: false }) realSbGoods!: boolean
  goodsType = ''

  sorts = [
    {
      icon: 'el-icon-sort-up',
      label: '升序',
      command: 'up'
    },
    {
      icon: 'el-icon-sort-down',
      label: '降序',
      command: 'down'
    },
    {
      icon: 'el-icon-sort',
      label: '无序',
      command: '',
      hidden: true
    }
  ]

  currentSort = this.sorts[2]

  mounted() {
  }

  // 写一个组件更新的钩子函数，当组件更新时候
  // @Watch('goodsType')
  // watchGoodsList() {
  //   this.$emit('goodsTypeInit', this.goodsType)
  //   this.realGoodsList = []
  // }

  selectSort(sort = this.sorts[2]) {
    this.currentSort = sort

    let sortFn: (g1: GoodsProxy, g2: GoodsProxy) => number = null!

    if (sort.command === 'up') {
      sortFn = (g1, g2) => g1.marketPrice - g2.marketPrice
    } else if (sort.command === 'down') {
      sortFn = (g1, g2) => g2.marketPrice - g1.marketPrice
    }

    if (sortFn) {
      this.realGoodsList = this.realGoodsList.sort(sortFn)
    }

    this.$emit('sync', this.goodsType)
  }

  selectGoods() {
    $goods.selectGoods(true, [...this.realGoodsList]).then(resp => {
      console.log(resp)
      this.realGoodsList = resp
      this.$emit('sync', this.goodsType)
    })
  }

  moveGoodsToTop(index = 0, disabled = false) {
    if (disabled) return

    const goods = this.realGoodsList[index]
    const list = this.realGoodsList.filter((_, i) => i !== index)
    list.unshift(goods)
    this.realGoodsList = list
    this.$emit('sync', this.goodsType)
  }

  moveGoodsToPrev(index = 0, disabled = false) {
    if (disabled) return
    const goods = this.realGoodsList[index]
    const list = this.realGoodsList.filter((_, i) => i !== index)
    list.splice(index - 1, 0, goods)
    this.realGoodsList = list

    this.$emit('sync', this.goodsType)
  }

  removeGoods(index) {
    this.realGoodsList.splice(index, 1)
    this.$emit('sync', this.goodsType)
  }

  moveGoodsToNext(index = 0, disabled = false) {
    if (disabled) return

    const goods = this.realGoodsList[index]
    const list = this.realGoodsList.filter((_, i) => i !== index)
    list.splice(index + 1, 0, goods)
    this.realGoodsList = list

    this.$emit('sync', this.goodsType)
  }

  moveGoodsToBottom(index = 0, disabled = false) {
    if (disabled) return

    const goods = this.realGoodsList[index]
    const list = this.realGoodsList.filter((_, i) => i !== index)
    list.push(goods)
    this.realGoodsList = list

    this.$emit('sync', this.goodsType)
  }

  async batchOps(arg: string) {
    const state = {
      goods: '',
      pack: 'PACKLIST'
    }
    try {
      if (this.realGoodsList.length && state[arg] !== this.goodsType) {
        await this.$confirm(`切换${state[arg] ? '礼包' : '商品'}后, ${state[arg] ? '商品' : '礼包'}信息将丢失。是否继续？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
      }
      switch (arg) {
        case 'goods':
          if (this.goodsType === 'PACKLIST') this.realGoodsList.length = 0
          this.goodsType = $goods.goodsType = ''
          break;
        case 'pack':
          // 如果之前选择的是商品的话  需要清空之前的
          if (!this.goodsType) this.realGoodsList.length = 0
          this.goodsType = 'PACKLIST'
          $goods.goodsType = 'PACK'
          break;
        default:
          break;
      }
      this.$forceUpdate()
      this.selectGoods()
    } catch (error) {
      this.$message({
        type: 'info',
        message: '已取消切换'
      });
    }
  }

  updateGoodsType(val) {
    this.goodsType = val
  }
}
