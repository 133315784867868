
import { Component, Mixins } from 'vue-property-decorator'
import { TemplateExtra } from '../module'
import { GoodsProxy } from '@/pages/floor/renovation/services/goods'
import GoodsCard from '../$components/goods-card/goods-card.vue'
import Placeholder from './placeholder.vue'
import { getGoodsInfo } from '../../api'
import { getPackInfo } from '@/api/packs.js'
import { promises } from 'dns'

@Component({
  name: 'template-6',
  components: {
    GoodsCard,
    Placeholder
  }
})
export default class Template extends Mixins(TemplateExtra) {
  goodsList: GoodsProxy[] = []
  frontData: GoodsProxy[] = []
  frontPageSize = 20
  frontPageIndex = 0
  frontHasNextPage = true
  first: any = null
  isSubject = false

  mounted() {
    this.isSubject = JSON.parse(sessionStorage.getItem('subjectPage')!)
  }

  init() {
    this.data.onChange.subscribe(block => {
      let list = block.list || this.data.blockList || []
      const first = list[0]
      this.first = first
      const next = ['GOODSLIST', 'PACKLIST', 'SUBJECT']
      if (first && next.includes(first.block_type)) {
        list = first.block_value.reduce(
          (map, goods) => {
            if (!map[goods.goods_id]) {
              map[goods.goods_id] = true
              map.array.push(goods)
            }
            return map
          },
          { array: [] }
        ).array
      }

      this.goodsList = list.map(g => GoodsProxy.create(g))

      this.resetPage()
      if (this.isSubject) this.mergeRequest(this.handleSubject(this.goodsList))
      else this.loadNextPage()
    })
  }

  resetPage() {
    this.frontHasNextPage = true
    this.frontPageIndex = 0
    this.frontData = []
  }

  loadNextPage() {
    console.log('loadNextPage', this.isSubject);

    if (!this.frontHasNextPage) return
    if (this.goodsList.length === 0) return

    const startIndex = this.frontPageIndex * this.frontPageSize
    let endIndex = (this.frontPageIndex + 1) * this.frontPageSize
    if (endIndex > this.goodsList.length) {
      endIndex = this.goodsList.length
      this.frontHasNextPage = false
    }
    const shopInfo2021: any = localStorage.getItem('shopInfo2021')
    if (this.first?.block_type === 'PACKLIST') {
      getPackInfo({
        shopId: JSON.parse(shopInfo2021).shop_id,
        combo_ids: this.goodsList.slice(startIndex, endIndex)
          .map((g: any) => {
            return String(g.goods_id || g.id)
          })
      }).then(res => {
        if (!res) return
        // 这里做了兼容性处理  兼容礼包跟商品的字段
        if (Array.isArray(res?.data) && res.data.length) {
          res.data.forEach(item => {
            item.goods_id = item.id // 为了兼容 商品id
            item.goods_name = item.gift_name // 为了兼容 商品名字
            item.revise_price = item.shop_sum_price // 为了兼容 套餐
            item.mktprice = item.market_sum_price // 为了兼容 分销
            item.goodsType = 'PACK' // 为了兼容 套餐
          })
        }
        // this.goodsList = res.data.map(g => GoodsProxy.create(g))
        const t = res.data.map(g => GoodsProxy.create(g))
        this.frontData = this.unique([
          ...this.frontData,
          ...t
        ])
        this.frontPageIndex++
      })
    } else {
      getGoodsInfo({
        shop_id: JSON.parse(shopInfo2021).shop_id,
        good_ids: this.goodsList.slice(startIndex, endIndex)
          .map(g => {
            return g.originalData.goods_id
          })
          .join(',')
      }).then(res => {
        if (!res) return console.log(res, this.goodsList);
        // console.log(res, this.goodsList);

        const t = res.map(g => GoodsProxy.create(g))
        this.frontData = this.unique([
          ...this.frontData,
          ...t
        ])
        this.frontPageIndex++
      })
    }
  }

  handleSubject(list: any[]) {
    if (!list.length) return
    if (!this.frontHasNextPage) return
    if (this.goodsList.length === 0) return
    const startIndex = this.frontPageIndex * this.frontPageSize
    let endIndex = (this.frontPageIndex + 1) * this.frontPageSize
    if (endIndex > this.goodsList.length) {
      endIndex = this.goodsList.length
      this.frontHasNextPage = false
    }
    const listMap: Array<string | number> = []
    const pack: Array<any> = []
    const goods: Array<any> = []
    list.slice(startIndex, endIndex).forEach(item => {
      if (item.goodsType === 'PACK') {
        pack.push(item)
      } else {
        goods.push(item)
      }
      listMap.push(item.goods_id || item.id)
    })
    return {
      listMap,
      pack,
      goods
    }
  }

  async mergeRequest(params: any) {
    if (!params) return
    const { listMap, pack, goods } = params

    const shopInfo2021: any = localStorage.getItem('shopInfo2021')

    const [packList, goodsList] = await Promise.all([getPackInfo({
      shopId: JSON.parse(shopInfo2021).shop_id,
      combo_ids: pack
        .map((g: any) => {
          return String(g.goods_id || g.id)
        }),
      page_size: this.frontPageSize,
    }), getGoodsInfo({
      shop_id: JSON.parse(shopInfo2021).shop_id,
      good_ids: goods
        .map(g => {
          return g.originalData.goods_id
        })
        .join(',')
    })])

    let packRes: Array<any> = []
    let goodsRes: Array<any> = []
    if (packList) {
      if (Array.isArray(packList?.data) && packList.data.length) {
        packList.data.forEach(item => {
          item.goods_id = item.id // 为了兼容 商品id
          item.goods_name = item.gift_name // 为了兼容 商品名字
          item.revise_price = item.shop_sum_price // 为了兼容 套餐
          item.mktprice = item.market_sum_price // 为了兼容 分销
          item.goodsType = 'PACK' // 为了兼容 套餐
        })
      }
      packRes = packList.data.map(g => GoodsProxy.create(g))
    }
    if (goodsList) {
      goodsRes = goodsList.map(g => GoodsProxy.create(g))
    }
    const mergeRes = Array.from({ length: listMap.length })
    packRes.forEach(item => {
      mergeRes[listMap.indexOf(item.goods_id || item.id)] = item
    })
    goodsRes.forEach(item => {
      mergeRes[listMap.indexOf(item.goods_id || item.id)] = item
    })
    this.frontData = this.unique([
      ...this.frontData,
      ...mergeRes as any
    ])

    this.frontPageIndex++
  }

  // 根据id 去重
  unique(arr: any[]) {
    const res: any[] = []
    const map: any = {}
    arr.forEach(item => {
      if (!map[item.goods_id || item.id]) {
        res.push(item)
        map[item.goods_id || item.id] = true
      }
    })
    return res
  }

  handleScroll() {
    return this.isSubject ? this.mergeRequest(this.handleSubject(this.goodsList)) : this.loadNextPage()
  }
}
