
import {Component, Mixins} from 'vue-property-decorator';
import {TemplateExtra} from '../module';
import {FloorMenuItem} from '@/pages/floor/renovation/services/floor-menu-item';

@Component({
  name: 'template-4',
})
export default class Template extends Mixins(TemplateExtra) {
  text: FloorMenuItem = null!;

  init() {
    this.data.onChange.subscribe(block => {
      const list = block.list || this.data.blockList || [];
      this.text = FloorMenuItem.createText(list[0])
    });
  }
}
