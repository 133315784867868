
import { Component, Mixins } from 'vue-property-decorator'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { TemplateExtra } from '../module'
import { GoodsProxy } from '../../services/goods'
import { SwiperProxy } from '../../services/swiper'
import GoodsCard from '../$components/goods-card/goods-card.vue'
import Placeholder from './placeholder.vue'
import { getGoodsInfo } from '../../api'
import { getPackInfo } from '@/api/packs.js'

@Component({
  name: 'template-5',
  components: {
    Swiper,
    SwiperSlide,
    GoodsCard,
    Placeholder
  }
})
export default class Template extends Mixins(TemplateExtra) {
  goodsList: GoodsProxy[] = []
  swiperProxy: SwiperProxy = null!

  get goodsListTop10() {
    if (this.goodsList.length > 10) {
      return this.goodsList.slice(0, 10)
    } else {
      return this.goodsList
    }
  }

  mounted() {
  }

  init() {
    this.swiperProxy = SwiperProxy.create({
      instance: this,
      key: this.data.key,
      refKey: 'swiper',
      extras: {
        slidesPerView: 'auto',
        spaceBetween: 0
      }
    })
    this.data.onChange.subscribe(block => {
      let list = block.list || this.data.blockList || []
      const first = list[0]
      const next = ['GOODSLIST', 'PACKLIST']
      if (first && next.includes(first.block_type)) {
        list = first.block_value || []
      }
      // const ids = list.map(g => {return g.goods_id}).join(',')
      // console.log(ids, 'ids')
      // this.goodsList = list.map(g => GoodsProxy.create(g))
      const shopInfo2021: any = localStorage.getItem('shopInfo2021')
      if (first?.block_type === 'PACKLIST') {
        // eslint-disable-next-line no-return-assign
        if (!list || !list.length) return this.goodsList = []
        getPackInfo({
          shopId: JSON.parse(shopInfo2021).shop_id,
          combo_ids: list
            .map((g: any) => {
              return String(g.goods_id)
            })
        }).then(res => {
          // eslint-disable-next-line no-return-assign
          if (!res) return this.goodsList = []
          // 这里做了兼容性处理  兼容礼包跟商品的字段
          if (Array.isArray(res?.data) && res.data.length) {
            res.data.forEach(item => {
              item.goods_id = item.id // 为了兼容 商品id
              item.goods_name = item.gift_name // 为了兼容 商品名字
              item.revise_price = item.shop_sum_price // 为了兼容 套餐
              item.mktprice = item.market_sum_price // 为了兼容 分销
              item.goodsType = 'PACK' // 为了兼容 套餐
            })
          }
          this.goodsList = res.data.map(g => GoodsProxy.create(g))
        })
      } else {
        getGoodsInfo({
          shop_id: JSON.parse(shopInfo2021).shop_id,
          good_ids: list
            .map((g: any) => {
              return g.goods_id
            })
            .join(',')
        }).then(res => {
          // eslint-disable-next-line no-return-assign
          if (!Array.isArray(res)) return this.goodsList = []
          this.goodsList = res.map(g => GoodsProxy.create(g))
        })
      }
    })
  }
}
