
import { Component, Vue, Watch } from 'vue-property-decorator'
import FloorModulePanel from './components/floor-module-panel/floor-module-panel.vue'
import MobilePreview from './components/mobile-preview/mobile-preview.vue'
import FloorModuleEditor from './components/floor-module-editor/floor-module-editor.vue'
import Album from './components/album/album.vue'
import AlbumPreview from './components/album/album-preview.vue'
import GoodsSelectorOld from './components/goods-selector-old/goods-selector-old.vue'
import { $floor, Floor } from './services/floor'
import { $scroller, Scroller } from '@/pages/floor/renovation/services/scroll'
import { floorVersions } from '@/api/floor'
import { getPriceUnit } from './api'

@Component({
  name: 'floor-renovation',
  components: {
    GoodsSelectorOld,
    FloorModuleEditor,
    MobilePreview,
    FloorModulePanel,
    Album,
    AlbumPreview
  }
})
export default class FloorRenovation extends Vue {
  floor: Floor = null!
  scroller: Scroller = null!
  disableEdit = false
  disableAction = false

  @Watch('floor', { immediate: true })
  watchFloor(newFloor) {
    if (newFloor) {
      floorVersions().then(resp => {
        sessionStorage.setItem('floorVersions-2.0', resp)
        if (resp - 0 === 0) this.floor.floorSwitch.next(false)
        else this.floor.floorSwitch.next(true)
      })
    }
  }

  @Watch('$route', {
    immediate: true,
    deep: true
  })
  loadFloor() {
    sessionStorage.setItem('subjectPage', 'false')
    const { params } = this.$route

    if (params.id) {
      switch (params.type) {
        case 'child':
          return $floor.getChild(params.id as any).then(floor => {
            this.floor = floor
            $floor.setBackground.next({
              type: 'setChildBack',
              data: sessionStorage.getItem('reeditChildBackground')
            })
          })
        case 'subject':
          sessionStorage.setItem('subjectPage', 'true')
          this.disableEdit = true
          this.disableAction = true
          if (params.id) {
            return $floor.getSubject(params.id as any).then(floor => {
              this.floor = floor
            })
          } else {
            this.floor = new Floor(null, false, 'SUBJECT_INDEX')
          }
          break
      }
    } else {
      $floor.get('WAP', 'INDEX').then(floor => {
        this.floor = floor
        $floor.setBackground.next({
          type: 'setBack',
          data: floor.floor_background || '#FFFFFF'
        })
        sessionStorage.setItem(
          'initBackground',
          floor.floor_background || '#FFFFFF'
        )
        sessionStorage.setItem(
          'reeditBackground',
          floor.floor_background || '#FFFFFF'
        )
      })
    }
  }

  mounted() {
    getPriceUnit().then(res => {
      sessionStorage.setItem('monetary_unit', res.monetary_unit ? res.monetary_unit : '')
    })
    this.scroller = $scroller.create(this.$el.parentElement!.parentElement!)
    $floor.scrollTo = y => this.scroller.toTop(y)
  }

  destroyed() {
    this.destroyFloor()
  }

  destroyFloor() {
    this.floor.destroy()
    this.floor = null!
    $floor.itemSelected.next(null!)
  }
}
