import {listChildFloors, listGoodsSpecialSubject} from '@/pages/floor/renovation/api';
import {Floor} from '@/pages/floor/renovation/services/floor';

export class FloorExtra {
  listChildFloors() {
    return listChildFloors({
      page_no: 1,
      page_size: 9999,
      client_type: 'MOBILE',
    }).then(resp => {
      return resp.data.map(f => new Floor(f, true, 'CHILD_INDEX'));
    });
  }

  listSubjects(pageIndex = 1, pageSize = 20, name?: string) {
    return listGoodsSpecialSubject({
      page_no: pageIndex,
      page_size: pageSize,
      navigation_name: name,
    }).then(resp => {
      return resp.data.map(item => new Floor(item, true, 'SUBJECT_INDEX'));
    });
  }
}

export const $floorExtra = new FloorExtra();
