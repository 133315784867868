<template>
  <x-dialog :proxy="goodsSelectorDialog">
    <!-- <el-tabs v-model="params.self_goods" v-if="!isJDSupplier" type="card" @tab-click="tabClick">
      <el-tab-pane v-for="item in fdList" :key="item.navigation_id" :label="item.navigation_name" :name="item.self_goods"
        :disabled="loading" />
    </el-tabs> -->
    <packSelectorTemplate :show="CoShow" :showChooseBtn="CoShowChooseBtn" :default-data="CoDefaultData"
      @close="(e) => $emit('close', e)" @choose="e => $emit('choose', e)" @chooseAll="e => $emit('chooseAll', e)">
    </packSelectorTemplate>
  </x-dialog>
</template>

<script>
import mixin from './mixin';
// import EnTableLayout from '../../../../../../ui-components/TableLayout/src/main';
import XDialog from '@/components/x-dialog/x-dialog';
import * as API_goods from "@/api/goods";
import packSelectorTemplate from './components/main.vue';

export default {
  name: 'EnGoodsPickerFx',
  components: {
    XDialog,
    packSelectorTemplate
  },
  mixins: [mixin],
  watch: {
  },
  methods: {
    /**
     * 获取区域数据
     * @param type
     * @param data
     */
    getAreaHandle (type, data = {}) {
      if (type === 'province') {
        API_goods.getJDProvince().then(res => {
          this.provinceList = this.dataHandle(res.data.result)
        })
      } else if (type === 'city') {
        API_goods.getJDCity(data.id).then(res => {
          this.cityList = this.dataHandle(res.data.result)
        })
      } else if (type === 'county') {
        API_goods.getJDCounty(data.id).then(res => {
          this.countyList = this.dataHandle(res.data.result)
        })
      } else if (type === 'town') {
        API_goods.getJDTown(data.id).then(res => {
          this.townList = this.dataHandle(res.data.result)
        })
      }
    },
    dataHandle (data) {
      const returnData = []
      const keys = Object.keys(data)
      const values = Object.values(data)
      keys.forEach((key, index) => {
        const data = {
          name: key,
          id: values[index]
        }
        returnData.push(data)
      })
      return returnData
    },
    /**
     * 查看商品
     */
    handleComboGoodsNum (row) {
      console.log(row)
      this.comboGoodsNumVisible = true
      this.comboGoodsNumTableData.data = []
      row.goods_volist.forEach(item => {
        const {
          web_thumbnail,
          supplier_name,
          goods_alias,
          goods_name,
          num,
          shop_goods_vo: {
            goods_do: { goods_source, thumbnail }
          }
        } = item
        this.comboGoodsNumTableData.data.push({
          goods_name,
          goods_alias,
          goods_source,
          num,
          web_thumbnail,
          thumbnail,
          supplier_name
        })
      })
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-table--border {
  // height: 570px !important;
  height: 56vh !important;
}

.goods-selector-dialog {
  .el-tabs__header {
    margin: 0;
  }

  .el-scrollbar__view {
    height: 80vh;
  }
}
</style>
