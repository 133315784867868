
import { Component, Vue } from 'vue-property-decorator';
import { $goods, PacksProxy } from '@/pages/floor/renovation/services/goods';
// const packSelector = () => import('@/pages/floor/renovation/components/pack-selector/main.vue')
import packSelector from '@/pages/floor/renovation/components/pack-selector/main.vue'

@Component({
  name: 'goods-selector-old',
  components: { packSelector }
})
export default class GoodsSelectorOld extends Vue {
  display = false;
  multiple = false;
  resolveGoods: any = null;
  selectedGoodsList: any[] = [];
  goodsType = $goods.goodsType;

  close() {
    this.display = false;
  }

  created() {
    $goods.selectGoods = (multiple, selectedGoods) => this.selectGoods(multiple, selectedGoods?.map(g => g.originalData));
  }

  selectGoods(multiple?: boolean, selectedGoodsList: any[] = []) {
    this.goodsType = $goods.goodsType;
    return new Promise<any>(resolve => {
      this.multiple = !!multiple;
      this.selectedGoodsList = selectedGoodsList;
      this.$nextTick(() => (this.display = true))
      this.resolveGoods = (goods: any[]) => {
        // 如果是选择礼包 则将商品的 goodsType 设置为 PACK
        if (this.goodsType === 'PACK') {
          goods.forEach(g => {
            g.goodsType = 'PACK'
          });
        }
        // 这里重写了排序逻辑 保证最后已选商品在前
        const set = new Set();
        this.selectedGoodsList.forEach(g => set.add(g.goods_id));
        // 新选择的商品
        const tempGoods = goods.filter(g => !set.has(g.goods_id));
        // 之前已选商品
        const tempSelectedGoods = goods.filter(g => set.has(g.goods_id));
        // 将新选择的商品放在前面
        goods = [...tempGoods, ...tempSelectedGoods];
        resolve(goods.map(g => new PacksProxy(g)));
        this.display = false;
      };
    })
  }

  // unique(arr: any[]) {
  //   debugger
  //   const map = new Map();
  //   arr.forEach(item => {
  //     const key = item.goods_id;
  //     if (!map.has(key)) {
  //       map.set(key, item);
  //     }
  //   });
  //   return [...map.values()];
  // }

  select(item: any) {
    this.resolveGoods([item]);
  }

  selectAll(list: any[]) {
    this.resolveGoods(list);
  }
}
