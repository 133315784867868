<template>
  <div>
    <ckeditor :id="id" @input="change" v-model="editorData" :config="editorConfig"></ckeditor>
  </div>
</template>

<script>
export default {
  name: 'ckeditor4',
  props: [
    'value'
  ],
  data () {
    return {
      id: parseInt(Math.random() * 10000).toString(),
      editorData: '<p>Content of the editor.</p>',
      editorConfig: {
        // The configuration of the editor.
      }
    }
  },
  methods: {
    change() {
      this.$emit('input', this.editorData)
    }
  },
  watch: {
    // 监听prop的变化，更新ckeditor中的值
    value: {
      handler (n, o) {
        if (this.value !== this.editorData) {
          this.editorData = this.value;
        }
      },
      immediate: true
    }
  },
  // 销毁组件前，销毁编辑器
  beforeDestroy: function () {
    const self = this
    self?.ckeditor?.destroy()
  }
}
</script>
