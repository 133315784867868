import {FloorItem, FloorItemProxy} from '@/pages/floor/renovation/services/floor';
import {FloorMenuItem} from '@/pages/floor/renovation/services/floor-menu-item';

export class FloorItem3Proxy extends FloorItemProxy {
  images!: FloorMenuItem[];

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    const items: any[] = item.blockList;
    this.images = [0, 1].map(index => FloorMenuItem.createImage(items[index]));
  }

  hasError() {
    return this.images.some(b => b.hasError());
  }

  toJson() {
    return this.images.map(image => image.toImageJson());
  }

  appendImage() {
    this.images.push(FloorMenuItem.createImage(null));
  }

  removeImage(target: FloorMenuItem) {
    this.images = this.images.filter(item => item.key !== target.key);
  }

  resetImage(target: FloorMenuItem) {
    target.reset();
  }
}
