
import {Component, PropSync, Vue, Watch} from 'vue-property-decorator';
import draggable from 'vuedraggable';
import {$floorModules} from '@/pages/floor/renovation/modules';
import {FloorModule} from '../../modules/module.config';
import {Floor, FloorItem} from '@/pages/floor/renovation/services/floor';
import BackgroundRender from '../background-render/background-render.vue';

@Component({
  name: 'floor-module-panel',
  components: {
    BackgroundRender,
    draggable,
  },
})
export default class FloorModulePanel extends Vue {
  @PropSync('floor') realFloor!: Floor;
  floorSwitch:any = 0;
  modules = $floorModules.modules;

  draggableOptions = {
    sort: false,
    group: {
      name: 'floor',
      pull: 'clone',
      put: false
    },
  };

  @Watch('realFloor', {immediate: true})
  watchFloor(newSrc) {
    if (newSrc) {
      this.realFloor.floorSwitch.subscribe(value => {
        this.floorSwitch = value
      })
    }
  }

  clone(item: FloorModule) {
    return FloorItem.create(item, true);
  }

  beforeMount() {
    const switchValue:any = sessionStorage.getItem('floorVersions-2.0')
    this.floorSwitch = switchValue - 0
  }

  mounted() {
    const unwatch = this.$watch('isReady', function(newValue) {
      unwatch()
    });
  }
}
