
import { Component, Mixins } from 'vue-property-decorator';
import { EditorExtra } from '../module';
import config from './$config';
import { FloorItem6Proxy } from './$proxy';
import FloorEditorFrame from '../$components/floor-editor-frame/floor-editor-frame.vue';
import GoodsEditor from '../$components/goods-editor/goods-editor.vue';

@Component({
  name: 'editor-6',
  components: {
    GoodsEditor,
    FloorEditorFrame,
  }
})
export default class Editor extends Mixins(EditorExtra) {
  config = config;
  proxy: FloorItem6Proxy = null!;
  initGoodsType = ''; // 回显的时候 初始化商品类型
  created(): void {
    if (this.realItem?.blockList?.[0]?.block_type === 'PACKLIST') {
      this.initGoodsType = 'PACK';
    } else {
      this.initGoodsType = '';
    }
  }

  init(): any {
    this.proxy = new FloorItem6Proxy(this.realItem);
    this.proxy.onResetCallBack = () => {
      const editor = this.$refs.editor as any;
      editor.selectSort();
    };
  }

  // 选择商品类型 PACK 为礼包 空字符串 为商品
  selectGoodsType(type: string) {
    this.proxy.goodsType = type;
  }
}
