
import {Component, PropSync, Vue, Watch} from 'vue-property-decorator';
import draggable from 'vuedraggable';
import {$floor, Floor} from '@/pages/floor/renovation/services/floor';
import {Device} from './services/device';
import MasterControlPanel from './components/master-control-panel.vue';
import SearchBox from './components/search-box.vue';

  @Component({
    name: 'mobile-preview',
    components: {
      SearchBox,
      MasterControlPanel,
      draggable,
    },
  })
export default class MobilePreview extends Vue {
    @PropSync('floor') realFloor!: Floor;
    @PropSync('isEditing') realIsEditing!: boolean;
    @PropSync('disableAction', {default: false}) realDisableAction!: boolean;
    floorSwitch = false
    device = Device.default;
    draggableOptions = {
      sort: true,
      animation: 375,
      group: {
        name: 'floor',
        put: true,
      },
    };

    backgroundColor:any = '#FFFFFF';

    @Watch('realFloor', {immediate: true})
    watchFloor(newSrc) {
      if (newSrc) {
        this.realFloor.floorSwitch.subscribe(value => {
          this.floorSwitch = value
        })
      }
    }

    deviceChanged(device: any) {
      this.device = device;
    }

    backShowHandle(value) {
      if (value) {
        if (this.$route.path.split('/')[2] === 'child') this.backgroundColor = sessionStorage.getItem("reeditChildBackground");
        else this.backgroundColor = sessionStorage.getItem("reeditBackground");
      } else this.backgroundColor = '#FFF'
    }

    floorResorted(e: any) {
      if (e.added) e.added.element.edit();
      if (e.moved) e.moved.element.edit();
    }

    publish() {
      this.realFloor.publish().then(() => {
        if (this.$route.path.split('/')[2] === 'child') {
          const data:any = sessionStorage.getItem("reeditChildBackground")
          sessionStorage.setItem("initChildBackground", data);
          sessionStorage.setItem("reeditChildBackground", data);
        } else {
          const data:any = sessionStorage.getItem("reeditBackground")
          sessionStorage.setItem("initBackground", data);
          sessionStorage.setItem("reeditBackground", data);
        }
        this.$message.success(`${this.realFloor.title}发布成功`);
      });
    }

    reset() {
      this.$confirm('确定还原至上一次发布的版本吗？', '提示', {
        confirmButtonClass: 'danger-confirm'
      }).then(() => {
        if (this.$route.path.split('/')[2] === 'child') {
          const data:any = sessionStorage.getItem("initChildBackground")
          this.realFloor.reset();
          $floor.setBackground.next({type: 'setChildBack', data})
          sessionStorage.setItem("reeditChildBackground", data);
        } else {
          const data:any = sessionStorage.getItem("initBackground")
          this.realFloor.reset();
          $floor.setBackground.next({type: 'setBack', data})
          sessionStorage.setItem("reeditBackground", data);
        }
      }, () => {
        // ?
      });
    }

    preview() {
      // ?
    }

    mounted() {
      // 监听背景色状态
      $floor.setBackground.subscribe(value => {
        if (value?.type === 'setBack' || value?.type === 'setChildBack') this.backgroundColor = value.data
      })
    }
}
