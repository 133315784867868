
import {Component, Mixins, Watch} from 'vue-property-decorator';
import {TemplateExtra} from '../module';
import {getCkeditor} from '../../api';

@Component({
  name: 'template-11'
})
export default class Template extends Mixins(TemplateExtra) {
  richText:any = [];
  boxId = 'richTextShow' + Math.ceil(Math.random() * 1000000)

  @Watch('richText', {deep: true, immediate: true})
  watchRichText(newValue) {
    if (newValue[0]) {
      this.$nextTick(() => {
        const content = newValue[0].content
        const dom:any = document.getElementById(this.boxId)
        if (content.substring(0, 4) === 'ext_') {
          if (dom) {
            const fromData:any = sessionStorage.getItem('shopInfo2021')
            const paramsData = {
              shop_id: JSON.parse(fromData).shop_id,
              ext_ids: this.richText[0].content
            }
            getCkeditor(paramsData).then(res => {
              dom.innerHTML = res[0].content
            })
          }
        } else dom.innerHTML = content
      })
    }
  }

  init() {
    this.data.onChange.subscribe(block => {
      const data:any = block.list || this.data.blockList || [];
      if (data[0]) this.richText = JSON.parse(JSON.stringify(data[0].block_value))
      else this.richText = []
    });
  }
}
