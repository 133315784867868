
import {Component, Vue} from 'vue-property-decorator';
import GoodsCard from '../$components/goods-card/goods-card.vue';
import {GoodsProxy} from '@/pages/floor/renovation/services/goods';

@Component({
  name: 'placeholder',
  components: {
    GoodsCard,
  }
})
export default class Placeholder extends Vue {
  goodsList: GoodsProxy[] = new Array(2).fill(0).map(() => new GoodsProxy(null));
}
