
import {Component, Vue} from 'vue-property-decorator';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {generateKey} from '@/pages/floor/renovation/services/key';
import {$album} from './services/album.service';

@Component({
  name: 'album-preview',
  components: {
    Swiper,
    SwiperSlide,
  }
})
export default class AlbumPreview extends Vue {
  display = false;
  images: { key: string; url?: string; info?: { desc?: string; width?: number; height?: number } }[] = [];
  swiperOptions = null as any;
  activeIndex = 0;
  width = 'auto';

  get swiper() {
    return (this.$refs.swiper as any)?.$swiper;
  }

  get currentImage() {
    return this.images[this.activeIndex];
  }

  get desc() {
    return this.currentImage?.info?.desc || '';
  }

  created() {
    this.swiperOptions = {
      autoHeight: true,
      on: {
        slideChange: s => {
          this.activeIndex = s.activeIndex;
          this.calcWidth();
        }
      }
    };
  }

  mounted() {
    $album.viewImages = (...args) => this.viewImages(...args);
  }

  resetWidth() {
    this.width = 'auto';
  }

  calcWidth() {
    if (this.currentImage?.info?.width) {
      let width = this.currentImage.info.width + 16;
      if (width < 200) width = 200;
      this.width = `${width}px`;
    }
  }

  viewImages(urls: string[], currentIndex = 0) {
    this.images = urls.map(url => {
      return {
        key: generateKey(),
        url,
        info: {}
      }
    });
    this.display = true;
    this.$nextTick(() => {
      this.swiper?.slideTo(currentIndex);
    });
  }

  imageLoaded(index: number, e: any) {
    const image = e.path[0];
    this.images[index].info = {
      desc: `（${image.naturalWidth} • ${image.naturalHeight}）`,
      width: image.naturalWidth,
      height: image.naturalHeight,
    };
    this.calcWidth();
    setTimeout(() => {
      this.swiper.update();
    }, 375);
  }
}
