
import { Component, Mixins, PropSync, Watch } from 'vue-property-decorator'
import { TemplateExtra } from '../module'
import Placeholder from './placeholder.vue'
import { Floor } from '@/pages/floor/renovation/services/floor'
import GoodsCard from '../$components/goods-card/goods-card.vue'
import { getGoodsInfo } from '../../api' //, getPriceUnit
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { SwiperProxy } from '../../services/swiper'
import { getPackInfo /** 获取礼包 */ } from '@/api/packs.js'
const packTopLeft = () => import('../$components/pack-top-left/index.vue') // 礼包左上角标
@Component({
  name: 'template-9',
  components: {
    Placeholder,
    GoodsCard,
    Swiper,
    SwiperSlide,
    packTopLeft
  }
})
export default class Template extends Mixins(TemplateExtra) {
  @PropSync('floor') realFloor!: Floor
  elevatorData = {
    titleBack: '#fff',
    arrangementMode: null,
    subSwitch: false,
    floorData: [{ goodsList: [], goodsType: '' }]
  }

  selectIndex = 0
  elevatorDataShow: any = {}
  swiperProxy: SwiperProxy = null!
  priceUnit = ''
  unitSuffix = false
  shopInfo2021: any = sessionStorage.getItem('shopInfo2021')

  @Watch('realFloor', { immediate: true })
  watchFloor(newSrc) {
    if (newSrc) {
      this.realFloor.elevatorSelectFloor.subscribe(value => {
        if (value < this.elevatorData.floorData.length) {
          this.selectIndex = value
          this.loadHandle('init')
        }
      })
    }
  }

  loadHandle(type = 'auto') {
    const index = this.selectIndex
    // 删除编辑界面楼层后 保存楼层时，楼层数据为空，需要判断是删除获取上一个index的数据
    // if (!this.elevatorData.floorData[index]) this.selectIndex = --index
    const goodsType = this.elevatorData.floorData[index]?.goodsType ?? ''
    const data = JSON.parse(JSON.stringify(this.elevatorData.floorData?.[index]?.goodsList ?? []))
    // console.log(data, "template9")
    if (type === 'init' && data.length > 0) {
      if (data.length >= 20) {
        this.elevatorDataShow[index] = data.splice(0, 20)
      } else if (data.length < 20) {
        this.elevatorDataShow[index] = data
      }
      const shopInfo2021: any = localStorage.getItem('shopInfo2021')
      const shop_id = JSON.parse(shopInfo2021).shop_id
      const goodsArr = JSON.parse(JSON.stringify(this.elevatorDataShow[index]))
      // if (!ids) return
      // console.log("getGoodsInfo")
      // 获取礼包列表
      if (goodsType === 'PACKLIST') this.getPackInfo(shop_id, goodsArr, index, type)
      else {
        getGoodsInfo({
          shop_id,
          good_ids: goodsArr
            .map(g => {
              return g.originalData.goods_id
            })
            .join(',')
        }).then(res => {
          // console.log(res, goodsArr)
          const arr: any = []
          goodsArr.forEach(item => {
            res.forEach(item1 => {
              if (item.originalData.goods_id === item1.goods_id) {
                arr.push({
                  ...item,
                  originalData: { ...item.originalData, ...item1 }
                })
              }
            })
          })
          this.elevatorDataShow[index] = JSON.parse(JSON.stringify(arr))
          // console.log(this.elevatorDataShow[index], "elevatorDataShow")
          this.$forceUpdate()
        })
      }
    } else if (type === 'auto' && data.length > 0) {
      if (this.elevatorData.arrangementMode !== '0') return
      const num = data.length - this.elevatorDataShow[index].length
      const length = this.elevatorDataShow[index].length
      const goodsArr =
        num >= 10
          ? JSON.parse(JSON.stringify(data.splice(length, 10)))
          : JSON.parse(JSON.stringify(data.splice(length, num)))
      const shopInfo2021: any = localStorage.getItem('shopInfo2021')
      const shop_id = JSON.parse(shopInfo2021).shop_id
      if (num <= 0) return
      const ids = goodsArr
        .map(g => {
          return g.originalData.goods_id
        })
        .join(',')
      if (goodsType === 'PACKLIST') this.getPackInfo(shop_id, goodsArr, index, type)
      else {
        getGoodsInfo({
          shop_id,
          good_ids: ids
        }).then(res => {
          const arr: any = []
          goodsArr.forEach(item => {
            res.forEach(item1 => {
              if (item.originalData.goods_id === item1.goods_id) {
                arr.push({
                  ...item,
                  originalData: { ...item.originalData, ...item1 }
                })
              }
            })
          })
          console.log(' this.elevatorDataShow[index] ', this.elevatorDataShow[index]);
          this.elevatorDataShow[index] = [...this.elevatorDataShow[index], ...arr]
          // console.log(this.elevatorDataShow[index], "elevatorDataShow")
          this.$forceUpdate()
        })
      }
    }
  }

  titleClick(index) {
    if (index === this.selectIndex) return
    this.selectIndex = index
    this.realFloor.elevatorSelectFloor.next(index)
    this.loadHandle('init')
  }

  init() {
    this.swiperProxy = SwiperProxy.create({
      instance: this,
      key: this.data.key,
      refKey: 'swiper',
      extras: {
        slidesPerView: 'auto',
        spaceBetween: 0
      }
    })

    this.data.onChange.subscribe(block => {
      const data = block.list || this.data.blockList || []
      if (data[0]?.block_value[0]) {
        this.elevatorData = JSON.parse(JSON.stringify(data[0].block_value[0]))
      } else {
        this.elevatorData = {
          titleBack: '#fff',
          arrangementMode: null,
          subSwitch: false,
          floorData: [{ goodsList: [], goodsType: '' }]
        }
      }
      this.loadHandle('init')
    })
    this.priceUnit = sessionStorage.getItem('monetary_unit') || '¥'
    this.unitSuffix = sessionStorage.getItem('monetary_unit') !== ''
    // getPriceUnit().then(res => {
    //   this.priceUnit = res.monetary_unit || '¥'
    //   if (res.monetary_unit) this.unitSuffix = true;
    //   else this.unitSuffix = false
    // })
  }

  getPackInfo(shopId, goodsArr, index, type) {
    getPackInfo({
      shopId,
      combo_ids: goodsArr
        .map(g => {
          return String(g.id)
        })
    }).then(res => {
      if (!res?.data?.length) return
      // 这里做了兼容性处理  兼容礼包跟商品的字段
      if (Array.isArray(res?.data) && res.data.length) {
        res.data.forEach(item => {
          item.goods_id = item.id // 为了兼容 商品id
          item.goods_name = item.gift_name // 为了兼容 商品名字
          item.revise_price = item.shop_sum_price // 为了兼容 套餐
          item.mktprice = item.market_sum_price // 为了兼容 市场总价
          item.goodsType = 'PACK' // 为了兼容 套餐
        })
      }
      // console.log(res, goodsArr)
      const arr: any = []
      goodsArr.forEach(item => {
        res.data.forEach(item1 => {
          if (item.originalData.goods_id === item1.goods_id) {
            arr.push({
              ...item,
              originalData: { ...item.originalData, ...item1 }
            })
          }
        })
      })
      console.log(' this.elevatorDataShow[index] ', this.elevatorDataShow[index]);

      if (type === 'init') this.elevatorDataShow[index] = JSON.parse(JSON.stringify(arr))
      else this.elevatorDataShow[index] = [...this.elevatorDataShow[index], ...arr]
      // console.log(this.elevatorDataShow[index], "elevatorDataShow")
      this.$forceUpdate()
      // this.goodsEditorList = res.data.map(g => (g.goods_id ? GoodsProxy : PacksProxy).create(g))
      // this.realGoodsList = res.data.map(g => (g.goods_id ? GoodsProxy : PacksProxy).create(g))
      // console.log(this.goodsEditorList, 'this.goodsEditorList');
    })
  }

  packFloatPrice(price) {
    if (price.toString().split('.').length > 1) return '';
    else return '.00';
  }
}
