
import {Component, PropSync, Vue, Watch} from "vue-property-decorator";
import setHotspotConfig from "@/pages/floor/renovation/modules/$components/floor-menu-item-editor/components/set-hotspot-config.vue";
import {Floor} from "@/pages/floor/renovation/services/floor";

  @Component({
    name: 'set-hotspot',
    components: {setHotspotConfig}
  })
export default class SetHotspot extends Vue {
    @PropSync('src') realImgSrc!: any;
    @PropSync('data') realData!: any;
    @PropSync('floor') realFloor!: Floor;
    canvasId='canvas' + new Date().getTime();
    newCanvas:any=null; // 窗口变化自适应
    hotNum=0; // 设置热区数量
    boxWidth=0; // 设置热区容器宽度
    mouseDownData:any={x: 0, y: 0, type: null}; // 鼠标点击时的坐标数据
    dragging=false; // 鼠标长按移动状态
    rectangleList:any=[]; // 绘制的矩形列表
    rectangleDeleteList:any=[]; // 绘制的矩形删除按钮列表
    newRect:any={status: false}; // 正在绘制矩形信息对象
    configData:any={static: false, index: 0}; // 配置热区数据
    originalCanvasRatioW=0; // 原图与绘制图的缩放比例（原图/绘制图）
    originalCanvasRatioH=0; // 原图与绘制图的缩放比例（原图/绘制图）
    resizeStopID:any=null; // 窗口变化停止
    floorVersions:any=false

    /**
     * 监听图片路径并使用canvas进行图片绘制
     * @param newSrc 最新图片路径
     */
    @Watch('realImgSrc', {
      immediate: true,
      deep: true
    })
    imgSrc(newSrc, oldSrc) {
      if (newSrc) {
        if (oldSrc) {
          this.rectangleList = []
          this.rectangleDeleteList = []
          this.realData = []
          this.hotNum = 0
        }
        this.canvasDrawImg(newSrc)
      }
    }

    @Watch('realFloor', {immediate: true})
    watchFloor(newFloor) {
      if (newFloor) {
        this.realFloor.floorSwitch.subscribe(value => {
          this.floorVersions = !value
          if (!value) {
            this.rectangleList = []
            this.rectangleDeleteList = []
            this.realData = []
            this.hotNum = 0
            this.canvasDrawImg(this.realImgSrc)
            this.$emit('setHotAuto')
          }
        })
      }
    }

    /**
     * 监听canvas对象的变化并处理鼠标事件
     * @param newC 最新canvas对象
     */
    @Watch('newCanvas', {
      deep: true
    })
    canvasChange(newC) {
      this.canvasMouse(newC);
    }

    /**
     * 取消热区配置的修改
     * @param value 上一次的配置值
     */
    configHandle(value) {
      this.realData[this.configData.index].config = value
    }

    /**
     * 处理矩形相交问题
     * @param newRectList 最新矩形列表
     */
    @Watch('rectangleList', {
      deep: true
    })
    rectangleHandle(newRectList) {
      const info = JSON.parse(JSON.stringify(this.newRect))
      delete info.status
      info.config = {type: 'NONE', value: '', desc: '', extraData: ''} // 热区的配置数据
      if (newRectList.length > 2 && info.w) {
        let overlap = false
        // 判断两个矩形的中心坐标的水平和垂直距离
        for (let i = 0; i < this.realData.length; i++) {
          if (overlap) break;
          const vCentre = {x: this.realData[i].l + this.realData[i].w / 2, y: this.realData[i].t + this.realData[i].h / 2}
          const nCentre = {x: info.l + info.w / 2, y: info.t + info.h / 2}
          if (Math.abs(vCentre.x - nCentre.x) <= this.realData[i].w / 2 + info.w / 2 && Math.abs(vCentre.y - nCentre.y) <= this.realData[i].h / 2 + info.h / 2) overlap = true
        }
        if (overlap) {
          this.$message.error('热区之间不能出现重叠')
          this.canvasHandle('', 'overlap')
        } else this.realData.push(info)
      } else if (newRectList.length > 1 && info.w) {
        this.realData.push(info)
      }
      // 数据重置
      this.newRect = {status: false}
    }

    mounted() {
      this.$nextTick(() => {
        const boxEl: any = document.querySelector('.set-img-hot')
        this.boxWidth = boxEl.clientWidth
      })
      // 监听窗口尺寸变化，canvas随窗口尺寸自适应变化
      const _this = this
      window.addEventListener('resize', function() {
        clearTimeout(_this.resizeStopID);
        _this.resizeStopID = setTimeout(() => {
          const boxEl: any = document.querySelector('.set-img-hot')
          _this.boxWidth = boxEl.clientWidth
          if (_this.realImgSrc) {
            _this.canvasDrawImg(_this.realImgSrc)
          }
        }, 200)
      })
    }

    /**
     * 热区回显
     */
    hotEcho(canvas) {
      if (!this.realData) this.realData = []
      else {
        const context = canvas.getContext('2d');
        this.rectangleList = []
        this.rectangleDeleteList = []
        this.rectangleList.push(context.getImageData(0, 0, canvas.width, canvas.height))
        this.realData.forEach(value => {
          // 计算当前比例下的矩形位置
          const l = value.l / this.originalCanvasRatioW
          const t = value.t / this.originalCanvasRatioH
          const w = value.w / this.originalCanvasRatioW
          const h = value.h / this.originalCanvasRatioH
          // 绘制矩形
          context.save();
          context.beginPath();
          context.fillStyle = "rgba(0, 191, 226,0.6)";
          context.fillRect(l + 20, t + 20, w, h);
          context.beginPath();
          context.fillStyle = "rgba(162, 166, 166,0.8)";
          context.fillRect(l + w + 2, t + 20, 18, 18);
          context.stroke();
          context.restore();
          context.font = "15px Georgia"
          context.fillStyle = "#fff"
          context.fillText('✖', l + w + 5, t + 35)
          this.rectangleDeleteList.push({
            x: (l + w + 2) * this.originalCanvasRatioW,
            y: (t + 20) * this.originalCanvasRatioH,
            w: 18 * this.originalCanvasRatioW,
            h: 18 * this.originalCanvasRatioH,
          })
          this.rectangleList.push(context.getImageData(0, 0, canvas.width, canvas.height)) // 保存上一次数据
          this.hotNum = this.rectangleList.length - 1
        })
      }
    }

    /**
     * 设置热区的鼠标事件
     */
    canvasMouse(newCanvas) :void {
      const _this = this
      let rectangleW = 0
      let rectangleH = 0
      let rectangleT = 0
      let rectangleB = 0
      let rectangleL = 0
      let rectangleR = 0
      if (!_this.newCanvas) return
      const context = newCanvas.getContext('2d');
      // 添加初始画布
      if (!_this.rectangleList.length)_this.rectangleList.push(context.getImageData(0, 0, newCanvas.width, newCanvas.height))
      // 鼠标点击可绘制区域时储存起点坐标，点击热区矩形时配置热区
      newCanvas.onmousedown = function(e) {
        _this.dblCanvas(e, 'click', newCanvas)
      }
      // 鼠标点击移动时绘制矩形
      newCanvas.onmousemove = function(e) {
        e.preventDefault();
        // 每次绘制先清除上一次
        context.putImageData(_this.rectangleList[_this.rectangleList.length - 1], 0, 0)
        // 开始绘制矩形
        if (_this.dragging) {
          const point = _this.canvasHandle({x: e.clientX, y: e.clientY, type: e.which}, 'windowToCanvas')
          rectangleW = Math.abs(point.x - _this.mouseDownData.x)
          rectangleH = Math.abs(point.y - _this.mouseDownData.y)
          rectangleL = point.x > _this.mouseDownData.x ? _this.mouseDownData.x : point.x
          rectangleT = point.y > _this.mouseDownData.y ? _this.mouseDownData.y : point.y
          rectangleR = rectangleL + rectangleW
          rectangleB = rectangleT + rectangleH
          if (rectangleL >= 20 && rectangleT >= 20 && rectangleR <= newCanvas.width - 25 && rectangleB <= newCanvas.height - 25) {
            // 储存矩形信息
            _this.newRect.status = true
            _this.newRect.w = rectangleW * _this.originalCanvasRatioW
            _this.newRect.h = rectangleH * _this.originalCanvasRatioH
            _this.newRect.t = (rectangleT - 20) * _this.originalCanvasRatioH
            _this.newRect.b = (rectangleB - 20) * _this.originalCanvasRatioH
            _this.newRect.l = (rectangleL - 20) * _this.originalCanvasRatioW
            _this.newRect.r = (rectangleR - 20) * _this.originalCanvasRatioW
            // 绘制矩形
            context.save();
            context.beginPath();
            context.fillStyle = "rgba(0, 191, 226,0.6)";
            context.fillRect(rectangleL, rectangleT, rectangleW, rectangleH);
            context.stroke();
            context.restore();
          } else {
            _this.newRect.status = false
          }
        }
      }
      // 鼠标移动结束时储存矩形
      newCanvas.onmouseup = function(e) {
        e.preventDefault();
        if (_this.dragging && _this.newRect.status && _this.newRect.w > 25 && _this.newRect.h > 25) {
          _this.dragging = false
          context.save();
          context.beginPath();
          context.fillStyle = "rgba(162, 166, 166,0.8)";
          context.fillRect(rectangleL + rectangleW - 18, rectangleT, 18, 18);
          context.stroke();
          context.restore();
          context.font = "15px Georgia"
          context.fillStyle = "#fff"
          context.fillText('✖', rectangleL + rectangleW - 15, rectangleT + 15)
          _this.rectangleDeleteList.push({
            x: (rectangleL + rectangleW - 18) * _this.originalCanvasRatioW,
            y: rectangleT * _this.originalCanvasRatioH,
            w: 18 * _this.originalCanvasRatioW,
            h: 18 * _this.originalCanvasRatioH,
          })
          _this.rectangleList.push(context.getImageData(0, 0, newCanvas.width, newCanvas.height)) // 保存上一次数据
          _this.hotNum = _this.rectangleList.length - 1
        } else {
          _this.dragging = false
        }
      }
      // 阻止页面的右击菜单栏
      newCanvas.oncontextmenu = function(e) {
        e.preventDefault()
      }
    }

    dblCanvas(e, type, newCanvas) {
      if (this.floorVersions) return;
      e.preventDefault();
      if (this.dragging) return
      this.mouseDownData = this.canvasHandle({x: e.clientX, y: e.clientY, type: e.which}, 'windowToCanvas')
      const data = {x: (this.mouseDownData.x - 20) * this.originalCanvasRatioW, y: (this.mouseDownData.y - 20) * this.originalCanvasRatioH}
      // 配置热区
      this.realData.forEach((value, index) => {
        const seat = this.rectangleDeleteList[index]
        const del = data.x <= seat.x && data.x >= seat.x - seat.w && data.y <= seat.y && data.y >= seat.y - seat.h
        const config = data.x <= value.r && data.x >= value.l && data.y <= value.b && data.y >= value.t
        if (type === 'click' && del) {
          this.canvasHandle('', 'del', index)
        }
        if (type === 'dblclick' && config) {
          this.configData.static = true
          this.configData.index = index
        }
      })
      // 起点坐标
      if (type === 'click' && this.mouseDownData.x >= 20 && this.mouseDownData.y >= 20 && this.mouseDownData.x < newCanvas.width - 25 &&
        this.mouseDownData.y < newCanvas.height - 25 && !this.configData.static) {
        this.dragging = true
      }
    }

    /**
     * 设置热区数据处理
     * @param type 数据类型
     */
    canvasHandle(data:any, type:string, index = 0) :any {
      if (!this.newCanvas) return
      const context = this.newCanvas.getContext('2d');
      if (type === 'windowToCanvas') { // 坐标转化为canvas坐标
        const bbox = this.newCanvas.getBoundingClientRect();
        // bbox 的宽度会加上 canvas 的 border 会影响精度
        return {
          x: data.x - bbox.left * (this.newCanvas.width / bbox.width),
          y: data.y - bbox.top * (this.newCanvas.height / bbox.height),
          type: data.type
        }
      } else if (type === 'del') {
        if (this.rectangleList.length > 1) {
          this.realData.splice(index, 1)
          this.rectangleList.splice(index, 1)
          this.rectangleDeleteList.splice(index - 1, 1)
          this.canvasDrawImg(this.realImgSrc)
          this.hotNum = this.rectangleList.length - 1
        }
      } else if (type === 'overlap') {
        if (this.rectangleList.length > 1) {
          this.rectangleList.pop()
          this.rectangleDeleteList.pop()
          context.putImageData(this.rectangleList[this.rectangleList.length - 1], 0, 0)
          this.hotNum = this.rectangleList.length - 1
        }
      }
    }

    /**
     * 使用canvas绘制带坐标轴的图片
     */
    canvasDrawImg(imgSrc:string) :void {
      this.$nextTick(() => {
        const _this = this
        const canvas:any = document.getElementById(_this.canvasId)
        if (!canvas) return
        const context = canvas.getContext('2d');
        // 创建图片
        const createImg = new Image(); // 创建一个<img>元素
        createImg.src = imgSrc; // 设置图片源地址
        createImg.crossOrigin = ''; // 处理图片跨域问题
        createImg.onload = function() {
          const whRatio = createImg.width / createImg.height
          canvas.width = _this.boxWidth > 420 ? 420 : _this.boxWidth
          canvas.height = canvas.width / whRatio
          _this.originalCanvasRatioW = createImg.width / (canvas.width - 45)
          _this.originalCanvasRatioH = createImg.height / (canvas.height - 45)
          // 绘制图片与具有刻度数的坐标轴
          _this.$nextTick(() => {
            context.drawImage(createImg, 20, 20, canvas.width - 45, canvas.height - 45)// 绘制图片
            const AXIS_ORIGIN = {x: 20, y: 20}; // 原点位置
            const AXIS_TOP = canvas.height - 25; // y轴顶点位置
            const AXIS_RIGHT = canvas.width - 25; // x轴顶点位置
            const TICK_SPACING = 5; // 刻度线间距
            const AXIS_WIDTH = AXIS_RIGHT - AXIS_ORIGIN.x; // X轴长度
            const AXIS_HEIGHT = AXIS_TOP - AXIS_ORIGIN.y; // y轴长度
            const NUM_VERTICAL_TICKS = AXIS_HEIGHT / TICK_SPACING; // y轴上的点的最大值
            const NUM_HORIZONTAL_TICKS = AXIS_WIDTH / TICK_SPACING; // x轴上的点的最大值
            const TICK_WIDTH = 20; // 刻度线长度
            context.font = 'normal normal bold 12px arial';
            context.fillStyle = '#5f5f5f'

            /**
             * 画坐标轴
             */
            function drawAxis() :void {
              context.save();
              context.strokeStyle = 'black';
              context.lineWidth = 1.0;
              drawHorizontalAxis(); // x轴
              drawVerticalAxis(); // y轴
              context.lineWidth = 0.5;
              context.strokeStyle = 'black';
              drawVerticalAxisTicks(); // y轴刻度
              drawHorizontalAxisTicks(); // x轴刻度
              context.restore();
            }
            /**
             * 绘制x轴
             */
            function drawHorizontalAxis() :void {
              context.beginPath();
              context.moveTo(AXIS_ORIGIN.x, AXIS_ORIGIN.y);
              context.lineTo(AXIS_RIGHT, AXIS_ORIGIN.y);
              context.stroke();
            }
            /**
             * 绘制y轴
             */
            function drawVerticalAxis() :void {
              context.beginPath();
              context.moveTo(AXIS_ORIGIN.x, AXIS_ORIGIN.y);
              context.lineTo(AXIS_ORIGIN.x, AXIS_TOP);
              context.stroke();
            }
            /**
             * 绘制y轴刻度
             */
            function drawVerticalAxisTicks() :void {
              // 小刻度长度的临时变量
              let deltaY;
              for (let i = 0; i < NUM_VERTICAL_TICKS; i++) {
                context.beginPath();
                // 每5第五个刻度为长的小刻度
                if (i % 10 === 0) {
                  context.rotate(90 * Math.PI / 180); // 旋转90度
                  deltaY = TICK_WIDTH
                  if (i !== 0 && i === 10) {
                    context.fillText(i * 5, 5 * i, -2)
                  } else if (i !== 0) {
                    context.fillText(i * 5, 5 * i - 5, -2)
                  }
                  // 重置当前的变换矩阵为初始态
                  context.setTransform(1, 0, 0, 1, 0, 0);
                } else if (i % 2 === 0) deltaY = TICK_WIDTH / 3;
                else deltaY = TICK_WIDTH / 5

                context.moveTo(AXIS_ORIGIN.x - deltaY, AXIS_ORIGIN.y + i * TICK_SPACING);
                context.lineTo(AXIS_ORIGIN.x, AXIS_ORIGIN.y + i * TICK_SPACING);
                context.stroke();
              }
            }

            /**
             * 绘制x轴刻度
             */
            function drawHorizontalAxisTicks() :void {
              // 小刻度长度的临时变量
              let deltaY;

              for (let i = 0; i < NUM_HORIZONTAL_TICKS; i++) {
                context.beginPath();
                // 每5第五个刻度为长的小刻度
                if (i % 10 === 0) {
                  deltaY = TICK_WIDTH
                  context.fillText(i * 5, 5 * i + 24, 10)
                } else if (i % 2 === 0) deltaY = TICK_WIDTH / 3;
                else deltaY = TICK_WIDTH / 5

                context.moveTo(AXIS_ORIGIN.x + i * TICK_SPACING, AXIS_ORIGIN.y - deltaY);
                context.lineTo(AXIS_ORIGIN.x + i * TICK_SPACING, AXIS_ORIGIN.y);
                context.stroke();
              }
            }
            drawAxis();
            _this.newCanvas = canvas
            _this.hotEcho(canvas)
          });
        }
      })
    }
}
