
import {Component, Mixins, Watch} from 'vue-property-decorator';
import {EditorExtra} from '../module';
import config from './$config';
import {FloorItem12Proxy} from './$proxy';
import FloorEditorFrame from '../$components/floor-editor-frame/floor-editor-frame.vue';
import ExtraLink from '@/pages/floor/renovation/modules/$components/floor-menu-item-editor/components/extra-link.vue';
import {$album} from '@/pages/floor/renovation/components/album/services/album.service';
import {listChildFloors, listGoodsSpecialSubject} from '../../api/index'

@Component({
  name: 'editor-12',
  components: {
    FloorEditorFrame,
    ExtraLink
  }
})
export default class Editor extends Mixins(EditorExtra) {
  config = config;
  proxy: FloorItem12Proxy = null!;
  pixelNum = '125像素';
  rowWidth = 0;
  currentIndex = -1;
  realData:any = {};
  realFalse = false;
  cellSelect: any = {
    start: null,
    end: null,
    createTime: null,
    imgUrl: null,
    link: {type: 'NONE', value: '', desc: '', extraData: ''}
  };

  cellMouse: any = {
    over: null,
    result: null
  };

  rowOptions = [
    {value: 2, label: '2行'},
    {value: 4, label: '4行'},
    {value: 6, label: '6行'},
  ]

  columnOptions = [
    {value: 6, label: '6列'},
    {value: 4, label: '4列'},
    {value: 2, label: '2列'},
  ]

  @Watch('proxy.rubikCube', {deep: true, immediate: true})
  watchFloor(newValue) {
    this.realData = newValue[0]
  }

  @Watch('realData.column')
  watchDataColumn(newValue) {
    switch (newValue) {
      case 2:
        this.pixelNum = '375像素';
        break;
      case 4:
        this.pixelNum = '187.5像素';
        break;
      case 6:
        this.pixelNum = '125像素';
        break;
    }
    this.resetHandle('reset')
  }

  @Watch('realData.row')
  watchDataRow() {
    this.resetHandle('reset')
  }

  @Watch('realData.dataList', {deep: true})
  watchCellData(newValue) {
    newValue.forEach(v => {
      this.cellStyleHandle(v)
    })
  }

  @Watch('currentIndex')
  watchCurrentIndex(newValue, oldValue) {
    if (oldValue > -1 && newValue > -1 && this.realData.dataList[oldValue]) {
      const createTime = this.realData.dataList[oldValue].createTime
      const dom: any = document.getElementsByClassName(createTime)
      const close = document.querySelector('.close' + createTime)
      dom[0].style.color = '#7d7e80'
      dom[0].style.backgroundColor = '#fff'
      dom[0].style.border = '1px solid #E9EAEE'
      if (close) dom[0].querySelector('div').removeChild(close)
    }

    if (newValue > -1) {
      const createTime = this.realData.dataList[newValue].createTime
      const dom: any = document.getElementsByClassName(createTime)
      dom[0].style.color = '#155bd4'
      dom[0].style.backgroundColor = '#ebf7fc'
      dom[0].style.border = '1px solid #155bd4'
      dom[0].querySelector('div').appendChild(this.createImg(createTime))
    }
  }

  /**
   * 重置数据
   */
  resetHandle(type = 'allReset') {
    if (type === 'allReset') {
      this.proxy.rubikCube = [{
        row: 2,
        column: 6,
        gapValue: 0,
        dataList: []
      }]
    }
    this.currentIndex = -1

    this.cellSelect = {
      start: null,
      end: null,
      createTime: null,
      imgUrl: null,
      link: {type: 'NONE', value: '', desc: '', extraData: ''}
    }
    this.cellMouse = {
      over: null,
      result: null
    };
    this.realData.dataList = []
    this.removeDiv('all')
  }

  /**
   * 单元格选择事件
   * @param type 事件类型
   * @param row 单元格位置(行)
   * @param column 单元格位置(列)
   */
  cellHandle(type, row, column) {
    if (type === 'click') {
      // 处理选中单元格
      if (this.cellSelect.start) {
        this.cellSelect.end = this.cellMouse.over
        this.realData.dataList.push(JSON.parse(JSON.stringify(this.cellSelect)))
        this.currentIndex = this.realData.dataList.length - 1
        this.cellSelect = {
          start: null,
          end: null,
          createTime: null,
          imgUrl: null,
          link: {type: 'NONE', value: '', desc: '', extraData: ''}
        }
        this.cellMouse = {
          over: null,
          result: null
        }
      } else {
        this.cellSelect.start = {row, column}
        this.cellMouse.over = {row, column}
        this.cellSelect.createTime = 'create' + new Date().getTime()
        this.cellMouse.result = [row, row, column, column]
      }
    } else if (type === 'mouseover' && this.cellSelect.start) {
      let verify = false
      const verifyData = this.sortHandle(this.cellSelect.start, {row, column})
      for (let index = 0; index < this.realData.dataList.length; index++) {
        const data = this.sortHandle(this.realData.dataList[index].start, this.realData.dataList[index].end)
        for (let row = verifyData[0]; row <= verifyData[1]; row++) {
          if (row >= data[0] && row <= data[1]) {
            for (let column = verifyData[2]; column <= verifyData[3]; column++) {
              if (column >= data[2] && column <= data[3]) {
                verify = true
                break;
              }
            }
          }
        }
        if (verify) break;
      }
      if (!verify) {
        this.cellMouse.over = {row, column}
        this.cellMouse.result = this.sortHandle(this.cellSelect.start, this.cellMouse.over)
      }
    }
  }

  /**
   * 合并选中的单元格
   * @param data
   */
  cellStyleHandle(data) {
    const dataHandle = this.sortHandle(data.start, data.end)
    const width = this.rowWidth / this.proxy.rubikCube[0].column
    const config = {
      name: data.createTime,
      url: data.imgUrl,
      w: (dataHandle[3] - dataHandle[2] + 1) * width + 'px',
      h: (dataHandle[1] - dataHandle[0] + 1) * width + 'px',
      t: (dataHandle[0] - 1) * width + 'px',
      l: (dataHandle[2] - 1) * width + 'px',
    }
    const box: any = document.getElementById('editor-cell-box')
    if (!document.getElementsByClassName(data.createTime)[0]) box.appendChild(this.createDiv(config))
  }

  /**
   * 排序处理
   * @param data
   * @param dataTwo
   */
  sortHandle(data, dataTwo) {
    let returnData: any = []
    if (data.row >= dataTwo.row && data.column >= dataTwo.column) returnData = [dataTwo.row, data.row, dataTwo.column, data.column];
    else if (data.row >= dataTwo.row && data.column < dataTwo.column) returnData = [dataTwo.row, data.row, data.column, dataTwo.column];
    else if (data.row < dataTwo.row && data.column >= dataTwo.column) returnData = [data.row, dataTwo.row, dataTwo.column, data.column];
    else if (data.row < dataTwo.row && data.column < dataTwo.column) returnData = [data.row, dataTwo.row, data.column, dataTwo.column];
    return returnData
  }

  /**
   * 创建元素
   * @param config 配置项
   */
  createDiv(config) {
    const _this = this
    const divBoxDom = document.createElement('div')
    const divDom = document.createElement('div')
    const spanDom = document.createElement('span')
    spanDom.innerText = '请添加图片'
    divBoxDom.appendChild(divDom)
    divBoxDom.className = config.name
    divBoxDom.classList.add('editor-clear-sign')
    divBoxDom.style.position = 'absolute'
    divBoxDom.style.width = config.w
    divBoxDom.style.height = config.h
    divBoxDom.style.top = config.t
    divBoxDom.style.left = config.l
    divBoxDom.style.border = '1px solid #E9EAEE'
    divBoxDom.style.backgroundColor = '#fff'
    divBoxDom.style.color = '#7d7e80'
    divBoxDom.style.cursor = 'pointer'
    divBoxDom.style.overflow = 'hidden'
    divBoxDom.addEventListener('click', function () {
      _this.createDivClick(config.name)
    })

    divDom.style.position = 'relative'
    divDom.style.width = config.w
    divDom.style.height = config.h
    divDom.style.display = 'flex'
    divDom.style.alignItems = 'center'
    divDom.style.justifyContent = 'center'
    divDom.style.textAlign = 'center'
    divDom.style.backgroundPosition = 'center'
    divDom.style.backgroundRepeat = 'no-repeat'
    divDom.style.backgroundSize = 'cover'
    if (config.url) divDom.style.backgroundImage = `url(${config.url})`;
    else divDom.appendChild(spanDom)
    return divBoxDom
  }

  createImg(name) {
    const _this = this
    const imgDom = document.createElement('img')
    imgDom.src = '/assets/img/close.png'
    imgDom.className = 'close' + name
    imgDom.style.position = 'absolute'
    imgDom.style.width = '15px'
    imgDom.style.height = '15px'
    imgDom.style.top = '5px'
    imgDom.style.right = '5px'
    imgDom.addEventListener('click', function () {
      _this.removeDiv(name)
    })
    return imgDom
  }

  removeDiv(value) {
    const box: any = document.getElementById('editor-cell-box')
    if (value === 'all') {
      const domList = document.querySelectorAll('.editor-clear-sign')
      domList.forEach(dom => {
        box.removeChild(dom)
      })
    } else {
      const dom = document.querySelector('.' + value)
      for (let i = 0; i < this.realData.dataList.length; i++) {
        if (this.realData.dataList[i].createTime === value) {
          box.removeChild(dom)
          this.realData.dataList.splice(i, 1)
          this.currentIndex = -1
          break
        }
      }
    }
  }

  createDivClick(value) {
    this.realData.dataList.forEach((v, i) => {
      if (v.createTime === value) {
        this.currentIndex = i
      }
    })
  }

  uploadImg() {
    $album.selectImage().then(url => {
      const name = this.realData.dataList[this.currentIndex].createTime
      const dom: any = document.querySelector('.' + name)?.querySelector('div')
      if (dom.querySelector('span')) dom.removeChild(dom.querySelector('span'))
      dom.style.backgroundImage = `url(${url})`
      this.realData.dataList[this.currentIndex].imgUrl = url
    });
  }

  init(): any {
    this.proxy = new FloorItem12Proxy(this.realItem);
    const rowDom: any = document.querySelector('.editor-cell-row')
    if (rowDom) this.rowWidth = rowDom.clientWidth
    const link = this.proxy.rubikCube[0].dataList
    const params:any = {page_no: 1, page_size: 9999, client_type: 'MOBILE'}
    // 子楼层不存在时，链接类型设置为NONE
    listChildFloors(params).then(res => {
      link.forEach(value => {
        let status = false
        res.data.forEach(v => {
          if (v.url === value.link.value) {
            status = true
          }
        })
        if (!status && value.link.type === 'SUB_FLOOR') {
          value.link.type = 'NONE'
          value.link.value = ''
          this.proxy.onSave.next({silence: true})
        }
      })
    })
    // 商品专题不存在时，链接类型设置为NONE
    listGoodsSpecialSubject({page_no: 1, page_size: 9999}).then(res => {
      link.forEach(value => {
        let status = false
        res.data.forEach(v => {
          if (v.navigation_name === value.link.value) {
            status = true
          }
        })
        if (!status && value.link.type === 'SPEC_SUBJECT') {
          value.link.type = 'NONE'
          value.link.value = ''
          this.proxy.onSave.next({silence: true})
        }
      })
    })
  }
}
