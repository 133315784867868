/** 礼包相关API */
import request from "@/utils/request";

/** 获取礼包列表 */
export const getPackList = (params) => request({
  url: "seller/shopCombo/comboListTh",
  method: "get",
  loading: false,
  params
});

/** 根据ids获取礼包详情 */
export const getPackInfo = (params) => request({
  url: "seller/shopCombo/comboListTh",
  method: "get",
  loading: false,
  params
});
