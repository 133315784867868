import {FloorItem, FloorItemProxy} from '@/pages/floor/renovation/services/floor';
import {FloorMenuItem} from '@/pages/floor/renovation/services/floor-menu-item';

export class FloorItem1Proxy extends FloorItemProxy {
  banners!: FloorMenuItem[];

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    let slides: any[] = item.blockList || [];
    const first = slides[0];
    if (first?.block_type === 'IMAGELIST') {
      slides = first.block_value || [];
    }
    this.banners = slides.map(b => FloorMenuItem.createImage(b));
  }

  hasError() {
    return this.banners.some(b => b.hasError());
  }

  toJson() {
    return [{
      block_type: 'IMAGELIST',
      block_value: this.banners.map(b => b.toImageJson()),
    }]
  }

  appendBanner() {
    this.banners.push(FloorMenuItem.createImage(null));
  }

  removeBanner(target: FloorMenuItem) {
    this.banners = this.banners.filter(b => b.key !== target.key);
  }
}
