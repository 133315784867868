
import { Component, PropSync, Vue, Watch } from 'vue-property-decorator'
import { Subject } from 'rxjs'
import draggable from 'vuedraggable'
import GoodsCard from '../goods-card/goods-card.vue'
import { $goods, GoodsProxy, PacksProxy } from '@/pages/floor/renovation/services/goods'
import FloorEditorFrame from '../floor-editor-frame/floor-editor-frame.vue'
import { getGoodsInfo } from '../../../api'
import { getPackInfo } from '@/api/packs.js'

@Component({
  name: 'goods-editor',
  components: {
    FloorEditorFrame,
    GoodsCard,
    draggable
  }
})
export default class GoodsEditor extends Vue {
  @PropSync('title', { default: '' }) realTitle!: string
  @PropSync('disableAction', { default: false }) realDisableAction!: boolean
  @PropSync('onSave') realOnSave!: Subject<any>
  @PropSync('onReset') realOnReset!: Subject<any>
  @PropSync('onRemove') realOnRemove!: Subject<any>
  @PropSync('initGoodsType', { default: '' }) InitGoodsType!: string

  @PropSync('goodsList', {
    required: true,
    default: () => []
  }) realGoodsList!: (GoodsProxy | PacksProxy)[]

  @Watch('goodsType')
  watchGoodsType() {
    this.goodsEditorList = []
    this.realGoodsList = []
  }

  sorts = [
    {
      icon: 'el-icon-sort-up',
      label: '升序',
      command: 'up'
    },
    {
      icon: 'el-icon-sort-down',
      label: '降序',
      command: 'down'
    },
    {
      icon: 'el-icon-sort',
      label: '无序',
      command: '',
      hidden: true
    }
  ]

  goodsType = ''
  page_no = 0
  page_size = 20
  goodsEditorList: any = []
  shopInfo2021: any = localStorage.getItem('shopInfo2021')
  isSubject = false
  mounted() {
    this.goodsType = this.InitGoodsType
    this.isSubject = JSON.parse(sessionStorage.getItem('subjectPage')!)
    console.log(this.realGoodsList);
    if (this.isSubject) return this.mergeRequest()

    if (this.goodsType === 'PACK') {
      getPackInfo({
        shopId: JSON.parse(this.shopInfo2021).shop_id,
        combo_ids: this.realGoodsList
          .map(g => {
            return String(g.id)
          }),
        page_size: this.realGoodsList.length
      }).then(res => {
        if (!res?.data?.length) return
        // 这里做了兼容性处理  兼容礼包跟商品的字段
        if (Array.isArray(res?.data) && res.data.length) {
          res.data.forEach(item => {
            item.goods_id = item.id // 为了兼容 商品id
            item.goods_name = item.gift_name // 为了兼容 商品名字
            item.revise_price = item.shop_sum_price // 为了兼容 套餐
            item.mktprice = item.market_sum_price // 为了兼容 市场总价
            item.goodsType = 'PACK' // 为了兼容礼包
          })
        }
        this.goodsEditorList = res.data.map(g => (g.goods_id ? GoodsProxy : PacksProxy).create(g))
        this.realGoodsList = res.data.map(g => (g.goods_id ? GoodsProxy : PacksProxy).create(g))
        this.resetGoodsType(this.goodsType)
        console.log(this.goodsEditorList, 'this.goodsEditorList');
      })
    } else {
      getGoodsInfo({
        shop_id: JSON.parse(this.shopInfo2021).shop_id,
        good_ids: this.realGoodsList
          .map(g => {
            return g.id
          })
          .join(',')
      }).then(res => {
        if (!Array.isArray(res)) return
        this.goodsEditorList = res.map(g => (g.goods_id ? GoodsProxy : PacksProxy).create(g))
        this.realGoodsList = res.map(g => (g.goods_id ? GoodsProxy : PacksProxy).create(g))
      })
    }
  }

  getGoodsList: any = []

  currentSort = this.sorts[2]
  selectSort(sort = this.sorts[2]) {
    this.$emit('goodsType', this.goodsType)
    this.currentSort = sort

    let sortFn: (g1: GoodsProxy | PacksProxy, g2: GoodsProxy | PacksProxy) => number = null!

    if (sort.command === 'up') {
      sortFn = (g1, g2) => g1.marketPrice - g2.marketPrice
    } else if (sort.command === 'down') {
      sortFn = (g1, g2) => g2.marketPrice - g1.marketPrice
    }

    if (sortFn) {
      this.goodsEditorList = this.goodsEditorList.sort(sortFn)
      this.realGoodsList = this.realGoodsList.sort(sortFn)
    }

    this.$emit('sync')
  }

  goodsResorted() {
    this.currentSort = this.sorts[2]
    this.$emit('sync')
  }

  selectGoods() {
    console.log(this.goodsEditorList)
    // nextTick 解决清空选择后，再次选择商品，商品列表不更新的问题
    this.$nextTick(() => {
      $goods.selectGoods(true, [...this.goodsEditorList]).then(resp => {
        console.log(resp, 'resp')
        this.realGoodsList = [...resp]
        this.goodsEditorList = resp
        this.$emit('sync')
      })
    })
  }

  moveGoodsToTop(index = 0, disabled = false) {
    if (disabled) return
    this.$emit('goodsType', this.goodsType)
    const goods = this.goodsEditorList[index]
    const list = this.goodsEditorList.filter((_, i) => i !== index)
    list.unshift(goods)
    this.goodsEditorList = list
    this.realGoodsList = list
    this.$emit('sync')
  }

  moveGoodsToPrev(index = 0, disabled = false) {
    if (disabled) return
    this.$emit('goodsType', this.goodsType)
    const goods = this.goodsEditorList[index]
    const list = this.goodsEditorList.filter((_, i) => i !== index)
    list.splice(index - 1, 0, goods)
    this.goodsEditorList = list
    this.realGoodsList = list

    this.$emit('sync')
  }

  removeGoods(index) {
    this.$emit('goodsType', this.goodsType)
    this.goodsEditorList.splice(index, 1)
    this.realGoodsList.splice(index, 1)
    this.$emit('sync')
  }

  moveGoodsToNext(index = 0, disabled = false) {
    if (disabled) return
    this.$emit('goodsType', this.goodsType)
    const goods = this.goodsEditorList[index]
    const list = this.goodsEditorList.filter((_, i) => i !== index)
    list.splice(index + 1, 0, goods)
    this.goodsEditorList = list
    this.realGoodsList = list
    this.$emit('sync')
  }

  moveGoodsToBottom(index = 0, disabled = false) {
    if (disabled) return
    this.$emit('goodsType', this.goodsType)
    const goods = this.goodsEditorList[index]
    const list = this.goodsEditorList.filter((_, i) => i !== index)
    list.push(goods)
    this.goodsEditorList = list
    this.realGoodsList = list
    this.$emit('sync')
  }

  async batchOps(arg: string) {
    const state = {
      goods: '',
      pack: 'PACK'
    }
    try {
      if (this.realGoodsList.length && state[arg] !== this.goodsType) {
        await this.$confirm(`切换${state[arg] ? '礼包' : '商品'}后, ${state[arg] ? '商品' : '礼包'}信息将丢失。是否继续？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
      }
      switch (arg) {
        case 'goods':
          this.goodsType = $goods.goodsType = ''
          break;
        case 'pack':
          this.goodsType = $goods.goodsType = 'PACK'
          break;
        default:
          break;
      }
      this.$emit('goodsType', $goods.goodsType)
      this.selectGoods()
    } catch (error) {
      this.$message({
        type: 'info',
        message: '已取消切换'
      });
    }
  }

  resetGoodsType(arg) {
    switch (arg) {
      case '':
        this.goodsType = $goods.goodsType = ''
        break;
      case 'PACK':
        this.goodsType = $goods.goodsType = 'PACK'
        break;
      default:
        break;
    }
    this.$emit('goodsType', $goods.goodsType)
  }

  clickBUtton() {
    this.goodsType = $goods.goodsType = ''
    this.$emit('goodsType', $goods.goodsType)
    this.selectGoods()
  }

  /** 合并请求 */
  async mergeRequest() {
    const list = this.realGoodsList
    if (!Array.isArray(list) && !this.realGoodsList.length) return
    const packList = list.filter(g => g.goodsType === 'PACK')
    const goodsList = list.filter(g => g.goodsType !== 'PACK')
    const listMap = list.map(g => g.id)
    const [packIds, goodsIds] = [packList.map(g => g.id), goodsList.map(g => g.id).join(',')]
    const shop_id = JSON.parse(this.shopInfo2021).shop_id
    const p1 = getPackInfo({ shopId: shop_id, combo_ids: packIds, page_size: packIds.length })
    const p2 = getGoodsInfo({ shop_id, good_ids: goodsIds })
    const [packs, goods] = await Promise.all([p1, p2])
    // 兼容数据处理
    if (packs && Array.isArray(packs?.data) && packs.data.length) {
      packs.data.forEach(item => {
        item.goods_id = item.id // 为了兼容 商品id
        item.goods_name = item.gift_name // 为了兼容 商品名字
        item.revise_price = item.shop_sum_price // 为了兼容 套餐
        item.mktprice = item.market_sum_price // 为了兼容 分销
        item.goodsType = 'PACK' // 为了兼容 礼包
      })
    }

    const tempLst: Array<any> = Array.from({ length: listMap.length })
    for (let i = 0; i < Math.max(packs.data.length, goods.length); i++) {
      if (packs.data[i]) {
        const index = listMap.indexOf(packs.data[i].id)
        if (index !== -1) tempLst[index] = packs.data[i]
      }
      if (Array.isArray(goods) && goods[i]) {
        const index = listMap.indexOf(goods[i].id || goods[i].goods_id)
        if (index !== -1) tempLst[index] = goods[i]
      }
    }

    this.goodsEditorList = tempLst.map(g => (g.goods_id ? GoodsProxy : PacksProxy).create(g))
    this.realGoodsList = tempLst.map(g => (g.goods_id ? GoodsProxy : PacksProxy).create(g))
  }
}
