import {
  FloorItem,
  FloorItemProxy,
} from "@/pages/floor/renovation/services/floor";
import { GoodsProxy } from "@/pages/floor/renovation/services/goods";

export class FloorItem5Proxy extends FloorItemProxy {
  goodsList!: GoodsProxy[];

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    const block = item.blockList[0];
    if (block) {
      this.goodsList = (block.block_value || []).map((goods) => {
        return GoodsProxy.create(goods);
      });
    } else {
      this.goodsList = [];
    }
  }

  hasError() {
    return false;
  }

  toJson() {
    return [
      {
        block_type: this.goodsType === "PACK" ? "PACKLIST" : "GOODSLIST",
        block_value: this.goodsList.map((g) => g.toJson()),
      },
    ];
  }
}
