import {
  FloorItem,
  FloorItemProxy,
} from "@/pages/floor/renovation/services/floor";
import { GoodsProxy } from "@/pages/floor/renovation/services/goods";

export class FloorItem6Proxy extends FloorItemProxy {
  goodsList!: GoodsProxy[];

  constructor(item: FloorItem) {
    super(item);
    // 注册检测是否改变的函数
    item.isChange = () => this.isChange();
  }

  parse(item: FloorItem) {
    const block = item.blockList[0];
    if (block) {
      this.goodsList = block.block_value.reduce(
        (map, goods) => {
          if (!map[goods.goods_id]) {
            map[goods.goods_id] = true;
            map.array.push(GoodsProxy.create(goods));
          }
          return map;
        },
        { array: [] }
      ).array;
    } else {
      this.goodsList = [];
    }
  }

  hasError() {
    return false;
  }

  toJson() {
    // 这里处理的是商品专题的逻辑 保存的类型是SUBJECT 单个礼品类型是PACK 商品类型则没有 goodsType属性
    const isSubject = JSON.parse(sessionStorage.getItem("subjectPage")!);
    let block_type, block_value;
    // eslint-disable-next-line prefer-const
    block_value = this.goodsList.map((g) => {
      const goods = g.toJson();
      if (isSubject && g.goodsType === "PACK") goods.goodsType = "PACK";
      return goods;
    });
    if (isSubject) {
      block_type = "SUBJECT";
    } else {
      if (this.goodsType === "PACK") {
        block_type = "PACKLIST";
      } else {
        block_type = "GOODSLIST";
      }
    }
    return [{ block_type, block_value }];
  }
}
