export type ActionTypes =
  | "NONE"
  | "GOODS"
  | "PACK"
  | "KEYWORD"
  | "CATEGORY"
  | "SPEC_SUBJECT"
  // 'SHOP' |
  | "LINK"
  // 'URL' |
  | "SUB_FLOOR"
  | "SET_HOT";

export class Action {
  key: ActionTypes = "NONE";
  value = "";
  title = "";
  desc = "";
  extraData: any = {};
  errorText = "";

  static all = [
    {
      label: "无链接",
      value: "NONE",
    },
    {
      label: "商品详情",
      value: "GOODS",
    },
    {
      label: "礼包详情",
      value: "PACK",
    },
    {
      label: "商品搜索",
      value: "KEYWORD",
    },
    {
      label: "商品分类",
      value: "CATEGORY",
    },
    {
      label: "商品专题",
      value: "SPEC_SUBJECT",
    },
    // {
    //   label: '店铺详情',
    //   value: 'SHOP',
    // },
    {
      label: "外部链接",
      value: "LINK",
    },
    // {
    //   label: '页内链接',
    //   value: 'URL',
    // },
    {
      label: "子楼层",
      value: "SUB_FLOOR",
    },
    {
      label: "设置热区",
      value: "SET_HOT",
    },
  ];

  static create(data?: any) {
    return new Action(data);
  }

  constructor(data: any) {
    if (data) {
      const hasSame = Action.all.some((a) => a.value === data.opt_type);
      if (!hasSame) data.opt_type = "NONE";
      this.key = data.opt_type || "NONE";
      if (this.key !== "NONE") {
        this.title = data.opt_title;
        this.value = data.opt_value;
        this.desc = data.opt_desc;
        if (data.opt_extra_data) {
          if (typeof data.opt_extra_data === "string") {
            try {
              data.opt_extra_data = data.opt_extra_data
                .replace(/\\/g, "")
                .replace(/""/g, "");
              this.extraData = JSON.parse(data.opt_extra_data);
            } catch (e) {
              console.log(data.opt_extra_data);
            }
          } else {
            this.extraData = data.opt_extra_data;
          }
        }
      }
    }
  }

  exec() {
    // ?
  }

  reset() {
    this.key = "NONE";
    this.value = "";
    this.title = "";
    this.desc = "";
    this.extraData = {};
  }

  toJson() {
    return {
      opt_type: this.key,
      opt_title: this.title,
      opt_value: this.value || undefined,
      opt_desc: this.desc || undefined,
      opt_extra_data: this.extraData,
      keywords: `${this.key}${this.value}`,
    };
  }

  hasError() {
    if (this.key === "NONE" || this.key === "SET_HOT") {
      this.errorText = "";
      return false;
    }

    if (this.value) {
      if (this.key === "LINK") {
        this.value = this.value.toString();

        if (
          !this.value ||
          !this.value.startsWith("http") ||
          !this.value.includes("://")
        ) {
          this.errorText = "链接必须以 http:// 或 https:// 开头！";
          return true;
        }

        if (this.value.split(".").length < 2) {
          this.errorText = "请填写正确的链接！";
          return true;
        }
      }
      this.errorText = "";
      return false;
    }

    switch (this.key) {
      case "CATEGORY":
        this.errorText = "分类不可为空！";
        break;
      case "GOODS":
        this.errorText = "商品不可为空！";
        break;
      case "PACK":
        this.errorText = "礼包不可为空！";
        break;
      case "KEYWORD":
        this.errorText = "关键字不可为空！";
        break;
      case "LINK":
        this.errorText = "链接不可为空！";
        break;
      case "SPEC_SUBJECT":
        this.errorText = "商品专题不可为空！";
        break;
      case "SUB_FLOOR":
        this.errorText = "子楼层不可为空！";
        break;
    }

    return true;
  }
}
